<template>
  <form @submit.prevent="send(!v$.service.$invalid)">
    <Dialog
      v-model:visible="visibleDialog"
      :style="{ width: '920px' }"
      header="Fomulário de Serviços"
      :modal="true"
      @hide="hideDialog"
    >
      <Fieldset legend="Informações do Serviço">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-6">
            <label for="name">Nome</label>
            <InputText
              id="name"
              v-model="v$.service.name.$model"
              maxlength="100"
              placeholder="Digite a nome do serviço"
              :class="{ 'p-invalid': submitted && v$.service.name.$invalid }"
            />
            <small
              v-if="submitted && v$.service.name.minLength.$invalid"
              class="p-error"
              >Nome inválido. Mínimo 3 caracteres</small
            >
            <small
              v-else-if="submitted && v$.service.name.$invalid"
              class="p-error"
              >Nome é obrigatório.</small
            >
          </div>
          <div class="field col-12 md:col-6">
            <label for="generalAccess">URL de acesso</label>
            <InputText
              id="generalAccess"
              v-model="v$.service.generalAccess.$model"
              maxlength="100"
              placeholder="Digite a URL de acesso"
              :class="{
                'p-invalid': submitted && v$.service.generalAccess.$invalid,
              }"
            />
            <small
              v-if="submitted && v$.service.generalAccess.$invalid"
              class="p-error"
              >URL de acesso é obrigatório</small
            >
          </div>
          <div class="field col-12 md:col-6">
            <label for="internalAccess">Acesso interno</label>
            <InputText
              id="internalAccess"
              v-model="v$.service.internalAccess.$model"
              maxlength="100"
              placeholder="Digite o IP de acesso interno"
              :class="{
                'p-invalid': submitted && v$.service.internalAccess.$invalid,
              }"
            />
            <small
              v-if="submitted && v$.service.internalAccess.$invalid"
              class="p-error"
              >IP de acesso interno é obrigatório</small
            >
          </div>
          <div class="field col-12 md:col-6">
            <label for="externalAccess">Acesso externo</label>
            <InputText
              id="externalAccess"
              v-model="v$.service.externalAccess.$model"
              maxlength="100"
              placeholder="Digite o IP de acesso externo"
              :class="{
                'p-invalid': submitted && v$.service.externalAccess.$invalid,
              }"
            />
            <small
              v-if="submitted && v$.service.externalAccess.$invalid"
              class="p-error"
              >IP de acesso externo é obrigatório</small
            >
          </div>
          <div class="field col-12 md:col-6">
            <label for="ipServer">Servidor de Aplicação</label>
            <InputMask
              id="ipServer"
              v-model="v$.service.ipServer.$model"
              mask="999.999.999.999"
              placeholder="Digite o IP do Servidor Host"
              :class="{
                'p-invalid': submitted && v$.service.ipServer.$invalid,
              }"
            />
            <small
              v-if="submitted && v$.service.ipServer.$invalid"
              class="p-error"
              >IP de servidor de aplicação é obrigatório</small
            >
          </div>
          <div class="field col-12 md:col-6">
            <label for="ipDatabase">Servidor do Banco</label>
            <InputMask
              id="ipDatabase"
              v-model="v$.service.ipDatabase.$model"
              mask="999.999.999.999"
              placeholder="Digite o IP do servidor do banco de dados"
              :class="{
                'p-invalid': submitted && v$.service.ipDatabase.$invalid,
              }"
            />
            <small
              v-if="submitted && v$.service.ipDatabase.$invalid"
              class="p-error"
              >IP de servidor do banco é obrigatório</small
            >
          </div>
          <div class="field col-12 md:col-6">
            <label for="category">Categoria</label>
            <Dropdown
              id="category"
              v-model="v$.service.category.$model"
              optionLabel="description"
              :options="categories"
              placeholder="Selecione a categoria"
              :class="{
                'p-invalid':
                  submitted && v$.service.category.description.$invalid,
              }"
            />
            <small
              class="p-error"
              v-if="submitted && v$.service.category.description.$invalid"
              >Categoria é obrigatório.</small
            >
          </div>
          <div class="field col-12 md:col-6">
            <label for="description">Descrição</label>
            <Textarea
              id="description"
              v-model="v$.service.description.$model"
              placeholder="Digite a descrição do serviço. Release ou texto informativo (MIN: 10 CARACTERES)"
              :autoResize="true"
              :class="{
                'p-invalid': submitted && v$.service.description.$invalid,
              }"
            />
            <span
              v-if="submitted && v$.service.description.minLength.$invalid"
              class="p-error"
              >Descrição inválida. Mínimo 10 caracteres</span
            >
            <small
              v-else-if="submitted && v$.service.description.$invalid"
              class="p-error"
              >Descrição é obrigatório.</small
            >
          </div>
        </div>
      </Fieldset>
      <template #footer>
        <Button
          label="Salvar"
          class="p-button"
          icon="pi pi-check"
          @click="send(!v$.service.$invalid)"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button p-button-text"
          @click="hideDialog"
        />
      </template>
    </Dialog>
  </form>
</template>
<script>
//Models
import Service from "../../../models/service";

//Services
import ServiceService from "../../../service/service/service";
import CategoryService from "../../../service/category/category_service";

//VALIDATIONS
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["serviceSelected"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      service: new Service(),
      submitted: false,
      serviceService: new ServiceService(),
      categoryService: new CategoryService(),
      categories: [],
      initProgress: false,
    };
  },
  mounted() {
    this.findCategories();
  },
  validations() {
    return {
      service: new Service().validations(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.service.dialogForm;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.service.dialogForm = value;
      },
    },
  },
  methods: {
    send(isFormValid) {
      this.submitted = true;
      if (!isFormValid) {
        return;
      } else {
        if (this.service.id) {
          this.update();
        } else {
          this.create();
        }
      }
    },
    create() {
      this.submitted = true;
      this.serviceService
        .create(this.service)
        .then((data) => {
          this.$msgSuccess(data);
          this.$emit("findAll");
          this.hideDialog();
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    update() {
      this.submitted = true;
      this.serviceService
        .update(this.service)
        .then((data) => {
          if (data.status === 200) {
            this.initProgress = false;
            this.$msgSuccess(data);
            this.$emit("findAll");
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
          this.initProgress = false;
        });
    },
    hideDialog() {
      this.service = new Service();
      this.submitted = false;
      this.visibleDialog = false;
      this.initProgress = false;
    },
    getData() {
      this.service = this.serviceSelected;
    },
    findCategories() {
      this.categoryService.findAll().then((data) => (this.categories = data));
    },
  },
};
</script>
<style scoped></style>
